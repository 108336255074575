var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        { staticStyle: { "z-index": "1" } },
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" }
            },
            [
              _c("pb-user-mode-selector"),
              _vm.userMode === "T" && _vm.localPage === "plans"
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            color: "primary",
                            label: _vm.$t("remindersLabel"),
                            icon: "",
                            elevation: "0",
                            disabled: _vm.localAppBarDisabled
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.showRemindersMobileDrawer.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm.remindersMobileClickCounter === 1 ||
                          _vm.remindersMobileClickCounter === 2
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm.remindersMobileClickCounter === 1
                                        ? _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("fal fa-chevron-up")]
                                          )
                                        : _vm._e(),
                                      _vm.remindersMobileClickCounter === 2
                                        ? _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("fal fa-chevron-down")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [_c("v-icon", [_vm._v("fal fa-bell")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("v-icon", [_vm._v("fal fa-bell")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isTeacher
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "pb-btn",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideRightDrawerAdd,
                              expression: "hideRightDrawerAdd"
                            }
                          ],
                          attrs: {
                            id: "add",
                            color: "primary",
                            label: _vm.$t("addLabel"),
                            elevation: "0",
                            disabled: _vm.localAppBarDisabled,
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.localDrawerRightAdd = !_vm.localDrawerRightAdd
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isStudent && _vm.hasText(_vm.viewNotesText)
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _vm.$currentUser.isStudent
                        ? _c(
                            "pb-btn",
                            {
                              attrs: {
                                color: "primary",
                                label: _vm.$t("viewInformationLabel"),
                                icon: "",
                                disabled: _vm.localAppBarDisabled
                              },
                              on: { click: _vm.showViewNotesText }
                            },
                            [_c("v-icon", [_vm._v("fal fa-info")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      attrs: {
                        color: "primary",
                        label: _vm.$t("filterPlansLabel"),
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.showDrawerBottom.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-sliders-h")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        label: _vm.showDetails
                          ? _vm.$t("hideDetailsLabel")
                          : _vm.$t("showDetailsLabel"),
                        color: "primary",
                        disabled: _vm.localAppBarDisabled,
                        icon: "",
                        id: "hideShowDetails"
                      },
                      on: {
                        click: function($event) {
                          _vm.showDetails = !_vm.showDetails
                        }
                      }
                    },
                    [
                      _vm.showDetails
                        ? _c("v-icon", [_vm._v("fal fa-arrow-up-to-line")])
                        : !_vm.showDetails
                        ? _c("v-icon", [_vm._v("fal fa-arrow-down-from-line")])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      attrs: {
                        id: "print",
                        color: "primary",
                        label: _vm.$t("sendToLabel"),
                        icon: "",
                        disabled: _vm.localAppBarDisabled
                      },
                      on: { click: _vm.plansShowPrintOptions }
                    },
                    [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("print-options", { ref: "printOptions" }),
          _c("lesson-manage-status", { ref: "manageStatus" }),
          _c("school-status-dialog", { ref: "applyStatus" }),
          _c("pb-confirm", { ref: "confirm" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }