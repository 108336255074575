
import { Component } from 'vue-property-decorator';
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import SchoolYearsMixin from '@/mixins/school-years-mixin';
import PlansToolbar from './PlansToobar.vue';

@Component({
  mixins: [SchoolYearsMixin],
  components: {
    BaseToolbar
  }
})
export default class PlansBottomBar extends PlansToolbar {
}
