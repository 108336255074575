import { render, staticRenderFns } from "./PlansBottomToolbar.vue?vue&type=template&id=6c51eafb"
import script from "./PlansBottomToolbar.vue?vue&type=script&lang=ts"
export * from "./PlansBottomToolbar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCol,VIcon,VRow,VToolbar})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c51eafb')) {
      api.createRecord('6c51eafb', component.options)
    } else {
      api.reload('6c51eafb', component.options)
    }
    module.hot.accept("./PlansBottomToolbar.vue?vue&type=template&id=6c51eafb", function () {
      api.rerender('6c51eafb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/plans/PlansBottomToolbar.vue"
export default component.exports